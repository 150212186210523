import React from "react";
import PropTypes from "prop-types";
import ImageFallback from "Components/image_fallback";

const DesktopSmall2 = ({
	link, image, catSlug, category, title, authorSlug, authorImage, authorName, date, isDate,
}) => (
	<div className="desktop-small2">

		<a href={link} className="desktop-small2__image">
			<ImageFallback
				src={image}
				width={200}
				height={200}
				quality={70}
				objectFit="cover"
				alt="article"
				fallbackSrc={process.env.NEXT_PUBLIC_S3_IMAGE_BROKEN_2_1}
			/>
		</a>
		<div className="desktop-small2__content">
			<a href={catSlug} className="desktop-small2__tag">
				{category}
			</a>
			<a href={link} className="desktop-small2__title">{title}</a>
			<div className="desktop-small2__author">
				<a href={authorSlug} className="desktop-small2__author-image-container">
					<ImageFallback
						src={authorImage}
						alt="author"
						objectFit="cover"
						width={30}
						height={30}
						quality={70}
						fallbackSrc={process.env.NEXT_PUBLIC_S3_IMAGE_BROKEN_2_1}
					/>
				</a>
				<p className="desktop-small2__author-name">
					<a href={authorSlug}>
						{authorName}
					</a>
					{isDate && (
						<>
							・
							{ date }
						</>
					)}
				</p>
			</div>
		</div>

		<style jsx>
			{`
                .desktop-small2 {
                    display: grid;
                    grid-template-columns: 94px 1fr;
                    gap: 12px;
                }

                .desktop-small2__image {
                    width: 94px;
                    height: 94px;
                }
                
                .desktop-small2__content {
                    display: flex;
                    flex-flow: column;
                }

                .desktop-small2__tag {
                    color: #E66983;
                    text-transform: uppercase;
                    font-family: Roboto, sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    margin-bottom: 5px;
                }

                .desktop-small2__title {
                    color: var(--text-default);
                    text-overflow: ellipsis;
                    font-family: Poppins, sans-serif;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 28px;
                    text-transform: capitalize;

                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }

                .desktop-small2__author {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    margin-top: var(--space-12);
                }

                .desktop-small2__author-image-container {
                    width: 17px;
                    height: 17px;
                    border-radius: 50%;
                    overflow: hidden;
                }
                .desktop-small2__author-name {
                    text-transform: uppercase;
                    color: var(--text-sub);
                    font-family: Roboto, sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 12px;
                    text-transform: uppercase;
                    margin: 0;
                }

                .desktop-small2__author-name a {
                    color: #919EAB;
                }
            `}
		</style>
	</div>
);

DesktopSmall2.propTypes = {
	link: PropTypes.string,
	image: PropTypes.string,
	catSlug: PropTypes.string,
	category: PropTypes.string,
	title: PropTypes.string,
	authorSlug: PropTypes.string,
	authorName: PropTypes.string,
	authorImage: PropTypes.string,
	date: PropTypes.string,
	isDate: PropTypes.bool,
};

DesktopSmall2.defaultProps = {
	link: "",
	image: "",
	catSlug: "",
	category: "",
	title: "",
	authorSlug: "",
	authorName: "",
	authorImage: "",
	date: "",
	isDate: false,
};

export default DesktopSmall2;
